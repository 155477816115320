import { FREESPEE_SET_DYNAMIC_DATA } from '~/store/mutation-types';

const now = new Date();

// Get weekday (ex: monday) based on Date API
const getWeekDay = (day) => {
  return day.toLocaleDateString('en', { weekday: 'long' }).toLowerCase();
};

// Check if today is one of the closed days
const isClosedDay = (closed_days, opening_hours) => {
  const today = getTodayOpeningHours(opening_hours);

  if (!today) {
    return true;
  }

  return closed_days.some(
    (day) =>
      new Date(day).toLocaleDateString('en') === now.toLocaleDateString('en')
  );
};

const getTodayOpeningHours = (opening_hours) => {
  const weekday = getWeekDay(now);
  return opening_hours.find((day) => day[weekday]);
};

const isPastOpeningTime = (opening_hours) => {
  const today = getTodayOpeningHours(opening_hours);

  if (!today) {
    return true;
  }

  const startDate = new Date(`${now.toLocaleDateString('en')} ${today.start}`);
  return now > startDate;
};

const isPastClosingTime = (opening_hours) => {
  const today = getTodayOpeningHours(opening_hours);

  if (!today) {
    return true;
  }

  const endDate = new Date(`${now.toLocaleDateString('en')} ${today.end}`);
  return now > endDate;
};

export const isOutsideOpeningHours = (opening_hours, closed_days) => {
  return (
    isClosedDay(closed_days, opening_hours) ||
    !isPastOpeningTime(opening_hours) ||
    isPastClosingTime(opening_hours)
  );
};

export const getCallbackDates = (
  opening_hours = [{ start: '09:00', end: '18:00' }],
  closed_days = []
) => {
  let result = [];
  const DAYS_COUNT = 7;
  const MINUTES_STEP = 15;

  // compute next seven days opening-hours
  const days = [];
  opening_hours.forEach((hour, openingHoursIndex) => {
    for (let i = 0; i < DAYS_COUNT; i++) {
      const day = new Date();
      day.setDate(now.getDate() + i);
      if (hour[getWeekDay(day)]) {
        days[i] = { day, openingHoursIndex };
      }
    }
  });

  // format days
  result = days.map(({ day, openingHoursIndex }) => {
    const value = day.toISOString().split('T')[0];
    let label = new Intl.DateTimeFormat('fr-FR', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    }).format(day);

    label = label.replace(/\w\S*/g, (w) =>
      w.replace(/^\w/, (c) => c.toUpperCase())
    );

    return {
      date: day,
      value,
      label,
      openingHoursIndex,
    };
  });

  // compute time-slots for each day
  const roundTime = (time, isStartTime = false, step = MINUTES_STEP) => {
    var timeToReturn = new Date(time);
    timeToReturn.setMilliseconds(
      Math.ceil(timeToReturn.getMilliseconds() / 1000) * 1000
    );
    timeToReturn.setSeconds(Math.ceil(timeToReturn.getSeconds() / 60) * 60);
    timeToReturn.setMinutes(Math.ceil(timeToReturn.getMinutes() / step) * step);
    if (
      !isStartTime &&
      timeToReturn.getMinutes() - new Date(time).getMinutes() < 5
    ) {
      timeToReturn.setMinutes(timeToReturn.getMinutes() + step);
    }
    return timeToReturn;
  };

  result = result.map((day, index) => {
    let { date, openingHoursIndex } = day;
    let { start, end } = opening_hours[openingHoursIndex];

    const [startHour, startMinutes] = start.split(':');
    const startTime = date.setHours(startHour, startMinutes, 0, 0);
    const [endHour, endMinutes] = end.split(':');
    const endTime = date.setHours(endHour, endMinutes, 0, 0);

    let time = new Date(
      index === 0 && isPastOpeningTime(opening_hours)
        ? roundTime(now.getTime())
        : roundTime(startTime, true)
    );
    let times = [];
    let i = 0;

    while (time <= endTime) {
      let newTime = time.getTime() + MINUTES_STEP * 60 * 1000;
      const value = new Intl.DateTimeFormat('fr-FR', {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: 'Europe/Paris',
      }).format(time);

      times[i] = {
        value,
        label: value.replace(':', 'h'),
        timestamp: time.getTime(),
      };

      i = i + 1;
      time = new Date(newTime);
    }

    return { ...day, times };
  });

  // exclude closed days
  result = result.filter((day) => !closed_days.includes(day?.value));

  // exclude today if past closing time
  result =
    getTodayOpeningHours(opening_hours) && isPastClosingTime(opening_hours)
      ? result.slice(1)
      : result;

  // safeguard
  result = result.filter((o) => o !== undefined);

  return result;
};

export const setFreespeeDynamicData = (openingHours, closedDays, store) => {
  const data = {
    isOutsideOpeningHours: isOutsideOpeningHours(
      openingHours,
      closedDays.map((o) => o.day)
    ),
    callbacks: getCallbackDates(
      openingHours,
      closedDays.map((o) => o.day)
    ),
  };

  store.commit(`freespee/${FREESPEE_SET_DYNAMIC_DATA}`, data);
};
