
import callbackMixin from '~/utils/freespee/webcallback-mixin';
import mergeI18n from '~/utils/merge-i18n';
import formsI18n from '~/i18n/messages/fr-fr.forms.json';

export default {
  mixins: [callbackMixin],
  computed: {
    smartButtonContent() {
      return this.$store.state.layout.smartButtonContent;
    },
    hasIadvizeWidget() {
      return this.$store.state.layout.iAdvizeIsActive;
    },
    hasFixedButton() {
      return !!this.$store.state.layout.fixedButton;
    },
  },
  created() {
    mergeI18n(this.$i18n, formsI18n);
  },
};
