import { pushWebCallback } from '~/plugins/gtm';
import {
  FREESPEE_SET_CALLBACKS,
  LAYOUT_SET_IADVIZE_FLAG,
} from '~/store/mutation-types';
import { setFreespeeDynamicData } from '~/utils/freespee/get-freespee-callbacks';
import handleFreespeeUpdate from '~/utils/freespee/set-freespee-params';

export default {
  data() {
    return {
      hasIadvize: false,
      dataClone: null,
      freespee: {
        hasError: false,
        isLoading: false,
      },
    };
  },
  computed: {
    freespeeStaticData() {
      return this.$store.state.freespee.data.static;
    },
    freespeeDynamicData() {
      return this.$store.state.freespee.data.dynamic;
    },
    freespeeCallbacks() {
      return this.$store.state.freespee.callbacks;
    },
    freespeeCustomerId() {
      return this.$store.state.freespee.data.static.customerId;
    },
    freespeeData() {
      return {
        ...this.freespeeStaticData,
        ...this.freespeeDynamicData,
      };
    },
  },
  watch: {
    freespeeStaticData(updatedfreespeeStaticData) {
      this.updateFreespeeData(updatedfreespeeStaticData);
    },
  },
  mounted() {
    this.updateFreespeeData(this.freespeeStaticData);
    this.checkIadvizeImplementation();
  },
  methods: {
    handleWebcallbackMounted() {
      window.__fs_dncs_instance.trackPage(window.location.href);
    },
    checkIadvizeImplementation() {
      // If iAdvize window object, set vuex state
      const interval = setInterval(() => {
        if (window.iAdvize) {
          this.$store.commit(`layout/${LAYOUT_SET_IADVIZE_FLAG}`, true);
          clearInterval(interval);
        }
      }, 200);
      // Since we don't know if iAdvize will be available, we need to have a timeout
      setTimeout(() => {
        clearInterval(interval);
      }, 5000);
    },
    async handleWebcallbackLoad() {
      this.$nuxt.$loading.start();
      this.freespee.isLoading = true;
      try {
        const { freespeeCustomerId: customerId } = this;
        if (customerId === undefined) {
          throw new Error('Failed to request Freespee : missing contribution');
        }

        const res = await fetch(`/api/freespee/get/?customer_id=${customerId}`);
        if (!res.ok) {
          console.warn('Failed to request Freespee');
          return;
        }

        const freespee = await res.json();
        this.$store.commit(
          `freespee/${FREESPEE_SET_CALLBACKS}`,
          freespee.callbacks
            .filter((o) => o.completed === '0' && o.scheduled !== null)
            .map((o) => {
              const isoString = `${o.scheduled.split(' ').join('T')}Z`;
              return new Date(isoString).getTime();
            })
        );
      } catch (error) {
        this.freespee.hasError = true;
        console.error(error);
      }
      this.$nuxt.$loading.finish();
      this.freespee.isLoading = false;
    },
    async handleInputValue(value) {
      if (value.length > 13) {
        const userPhoneNumber = `+33${value.replace(/ /g, '').slice(1)}`;
        const testPhone = await fetch(
          `/phonetest?tel=${userPhoneNumber.toString()}`,
          { method: 'post' }
        );
        const isClient = await testPhone.json();

        if (isClient.isClient) {
          this.dataClone = { ...this.freespeeData };
          this.freespeeData.confirmationAsap =
            this.slice?.data.edito.isClient.content;
          this.freespeeData.confirmationScheduled =
            this.slice?.data.edito.isClient.content;
          this.freespeeData.title = this.slice?.data.edito.isClient.title;
          this.freespeeData.subtitle = '';
          this.freespeeData.incomingNumber = '';
          this.freespeeData.confirmationIcon = null;
        } else {
          if (this.dataClone != null) {
            this.freespeeData.confirmationAsap =
              this.dataClone.confirmationAsap;
            this.freespeeData.confirmationScheduled =
              this.dataClone.confirmationScheduled;
            this.freespeeData.title = this.dataClone.title;
            this.freespeeData.subtitle = this.dataClone.subtitle;
            this.freespeeData.incomingNumber = this.dataClone.incomingNumber;
            this.freespeeData.confirmationIcon =
              this.dataClone.confirmationIcon;
          }
        }
      }
    },
    async handleWebcallbackSubmit(payload) {
      this.$nuxt.$loading.start();
      this.freespee.isLoading = true;
      const { freespeeCustomerId: customerId } = this;
      if (customerId === undefined) {
        throw new Error('Failed to request Freespee : missing contribution');
      }
      const {
        date: scheduled,
        userPhoneNumber: requester_nr,
        agentPhoneNumber: agent_nr,
      } = payload;
      const data = { scheduled, requester_nr, agent_nr };

      const searchParams = new URLSearchParams();
      searchParams.set('customer_id', customerId);
      searchParams.set('retries', 3);
      Object.keys(data)
        .filter((key) => data[key])
        .forEach((key) => {
          searchParams.set(key, data[key]);
        });

      const res = await fetch(`/api/freespee/post?${searchParams.toString()}`, {
        method: 'post',
      });
      const json = await res.json();
      if (
        json &&
        json[0] &&
        json[0].callbacks &&
        json[0].callbacks.requester_nr
      ) {
        pushWebCallback(this);
      }
      if (!res.ok) {
        console.warn('Failed to request Freespee');
      }
      this.$nuxt.$loading.finish();
      this.freespee.isLoading = false;
    },
    getFreespeeData() {
      const { freespeeParams } = this.slice?.data;
      if (freespeeParams) handleFreespeeUpdate(freespeeParams);
    },
    updateFreespeeData(freespeeStaticData) {
      if (freespeeStaticData) {
        setFreespeeDynamicData(
          freespeeStaticData.openingHours,
          freespeeStaticData.closedDays,
          this.$store
        );
      }
    },
  },
};
